import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import ChevronDown from "../components/svg/chevronDown"

const ReactPage = () => (
    <Layout>
        <Seo title="React developers in Sydney and Canberra | React.js agency" 
            description="Experienced React developers in Sydney and Canberra. We develop web applications using the React.js Javascript library."
            pathname="/react-developers-sydney-canberra/"
        />

        <Hero
            h1="React Developers Sydney and Canberra"  
            h2="We develop modern web applications using React.js and GraphQL."
        /> 
        
        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">20+</span>
                            <p>We've developed more than 20 applications using React.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">2015</span>
                            <p>We've been developing React applications since 2015.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Big &amp; small</span>
                            <p>We've completed React apps for startups and large organisations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Experienced frontend &amp; backend development agency</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Launch Lab develops startups and web applications for larger organisations using the popular React.js Javascript library.
                            </p>
                            <p>
                                Having worked with React since 2015, we have loads of React applications in production including projects for startups, universities and established companies.
                            </p>
                            <p>
                                All of our Javascript development work is done onshore in Australia by a senior experienced team. 
                            </p>
                            <p>
                                We also offer <Link to="/python-developer/">backend development</Link> and <Link to="/aws-infrastructure/">AWS infrastructure</Link> services. For fast-loading high performing marketing websites we use <Link to="/gatsby-developer/">Gatsby</Link>.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all work
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you exclusively use React for frontend development? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. We've also used <Link to="/vue-developer/">Vue.js</Link> before and have a couple of Vue applications in production.</p>
                                <p>Unless you specify Vue, we'll almost always use React.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only offer frontend development? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. We offer full stack <Link to="/services/">web development services</Link> including web design, infrastructure, backend and frontend development.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    React vs Angular vs Vue. Which is better? <ChevronDown />
                                </>
                                }
                            >
                                <p>Our personal preference is to use React. We've spent a lot of time working with React and that's probably where we'll stay. If you want to take a deep dive to compare, simply do a Google search. You'll find loads of articles written on the subject.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Is React the best fit for my project? <ChevronDown />
                                </>
                                }
                            >
                                <p>If you're product is a web application then the answer will almost always be 'yes', but feel free to contact us to discuss your project and the best tech stack for your needs.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    How can I find out more about React? <ChevronDown />
                                </>
                                }
                            >
                                <p>ReactJS is an open source Javascript library that is maintained by Meta (nee Facebook). You can find out more on the official <a href="https://reactjs.org/">React website</a></p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <Cta 
            text="Get a ReactJS quote and / or a web development quote from a local onshore Australian Javascript development team"
        />
    </Layout>
)

export default ReactPage